.PullTabContent {
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 50%;
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;

  padding-top: 50px;
  transform: translate(-50%, -100%) scale(0.96);
  transition: transform 250ms cubic-bezier(0.56, -0.36, 0.5, 1.32);

  border: 2px ridge black;
  box-sizing: border-box;
}
.PullTabContent img {
  width: 100%;
}
.PullTabContent.open {
  transform: translate(-50%, -20px) scale(1);
}
.PullTabContent.hidden {
  transform: translate(-50%, calc(-100% - 60px)) scale(1) !important;
}
.PullTab {
  /* box-sizing: border-box; */
  /* position: absolute; */
  /* margin: auto; */
  /* bottom: -50px; */
  /* left: 50%; */
  /* transform: translate(-50%, 0); */

  /* width: 40px; */
  /* height: 40px; */
  /* border-top: none; */
  /* font-size: 30px; */
}

label {
  display: inline-block;
  width: 40%;
}
.PullTabContent h1 {
  margin-bottom: 0.5rem;
}
.PullTabContent p {
  margin-bottom: 0.5rem;
}
input {
  font-size: 1.2rem;
  width: 60%;
  display: inline-block;
  margin-bottom: 0.7rem;
  transition: all 100ms cubic-bezier(0.56, -0.36, 0.5, 1.32);
}

input:focus {
  transform: scale(1.025);
}
input[type="checkbox"] {
  height: 1.3rem;
  width: 1.3rem;
}
input[type="submit"] {
  border: 3px solid black;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: calc(1.5rem);
  width: fit-content;
  padding: 0.3rem 1rem;
  font-weight: bold;
  display: block;
  filter: drop-shadow(0px 0px 0px rgba(73, 71, 71, 0.548));
}

input[type="submit"]:hover {
  transform: scale(1.02);
  filter: drop-shadow(1px 2px 2px rgba(73, 71, 71, 0.348));
}
input[type="submit"]:active {
  transform: scale(1.1);
  background-color: rgb(192, 192, 247);
}
