.draggable {
  pointer-events: initial;
  transition: filter 300ms ease-in-out;
}
.draggable.selected {
}

.draggable.selected.move {
  /* border: 1px blue dashed; */
  /* filter: drop-shadow(0px 10px 15px rgba(15, 15, 15, 0.281))
    drop-shadow(0px 0px 1px white); */

  /* filter: drop-shadow(8px 16px 0px rgba(15, 15, 15, 0.281)); */
  transition: filter 100ms;
}

.handle-container {
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 10000;
  display: block;
  user-select: none;
  margin: 0;
  pointer-events: none;
}

.draggable:hover + .handle-container {
}
.handle-container:hover {
}
.handle-container.move {
  filter: drop-shadow(2px 4px 0px rgba(15, 15, 15, 0.281));
  transition: filter 100ms;
}
.handle-container button {
  pointer-events: all;

  max-width: 50%;
  max-height: 50%;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}
.handle-container svg {
  stroke: black;
  fill: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}
.duplicate {
  position: absolute;

  left: 0;
  top: 0;
  transform: translate(-50%, -50%);

  width: 25px !important;
  height: 25px !important;
  display: none;
}
.duplicate:hover,
.duplicate.active {
  filter: drop-shadow(2px 2px 0px rgba(0, 128, 0, 0.596));
  /* background-color: rgba(32, 52, 236, 0.705); */
}

.x {
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  position: absolute;
  filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(-1px 1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(1px -1px 1px rgba(255, 255, 255, 0.5));
}

.x:hover,
.x.active {
  filter: drop-shadow(2px 2px 2px red)
    drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(-1px 1px 1px rgba(255, 255, 255, 0.5))
    drop-shadow(1px -1px 1px rgba(255, 255, 255, 0.5));
}

.draggable {
  margin: 0;
  touch-action: manipulation;
}

@media screen and (max-width: 600px) {
  .handle-container .tool {
    zoom: 2;
  }
}

.resize-handle {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  box-sizing: border-box;
  z-index: 1000000;
  background-color: white;
  transform: translate(-50%, -50%);
  display: block;
  user-select: none;
  margin: 0;
  pointer-events: all;
  right: -18px;
  bottom: -18px;
  border-radius: 100%;
  cursor: grabbing;
}

textarea {
  font-size: 2rem;
  padding: 0.5rem;
  border: none;
  overflow: hidden;
  background: transparent;
}
textarea:focus {
  /* outline: 0; */
  /* border: .1px solid rgb(00, 0, 255,.4) */
}
textarea::-webkit-resizer {
  display: none;
}
.locked {
  pointer-events: none;
}

textarea,
h1 {
  text-shadow: -1px -1px 0 rgb(245, 245, 245), 1px -1px 0 rgb(245, 245, 245),
    -1px 1px 0 rgb(245, 245, 245), 1px 1px 0 rgb(245, 245, 245);
}
